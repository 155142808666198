<template>
  <div v-if="loading" class="loader">
    <img src="../assets/images/loader.svg" alt="Loader" decoding="async"/>
  </div>
</template>

<script>
import loaderImg from '../assets/images/loader.svg'

export default {
  props: ['loading']
}
</script>

<style scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

</style>
