<template>
  <div class="method-stripe">
    <b-input-group prepend="$" class="mb-1">
      <b-form-input
        :placeholder="`Enter Amount ${between.min}$ - ${between.max}$`"
        type="number"
        v-model="amount"
      />
    </b-input-group>
    <div class="text-error" v-if="amount > between.max">
      Can't pay more than {{ between.max }}$
    </div>
    <br />
    <button
      v-on:click="generateBinanceQR"
      class="btn btn-primary btn-lg btn-block"
      v-show="amount >= between.min && amount <= between.max"
    >
      Pay now!
    </button>

    <p class="binance_qr" v-if="qr_generated">
      <br />
      <center>
        <b>
          Open the Binance Application on your phone and scan the code below.<br /><br />
          If you cannot scan the QR code or you are on the phone, access the
          link below to pay.
          <br /><br />
          >>>
          <a :href="this.binance_checkout" target="_blank"
            >Direct Payment Link</a
          >
          <<<
        </b>
      </center>
      <br /><br />

      <center>
        This is auto payment! you don't have to wait!
      </center>
      <br />

      <br />
      <center>
        <a href="https://revolut.me/radutapmx">
          <img :src="qr_image" alt="Revolut" class="center" />
        </a>
      </center>
    </p>
  </div>
</template>

<script>
import { BInputGroup, BFormInput } from "bootstrap-vue";
import { mapMutations } from "vuex";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  props: ["between"],
  data: () => ({
    amount: "",
    qr_generated: false,
    binance_checkout: "",
    qr_image: "",
  }),
  methods: {
    ...mapMutations(["HANDLE_CREDIT"]),
    complete(amount) {
      this.HANDLE_CREDIT(+amount);
      // notify
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Deposit Made`,
          icon: "CheckIcon",
          text: `You have deposit ${amount}$`,
          variant: "success",
        },
      });
    },

    async generateBinanceQR() {
      await axios
        .post(
          //  `http://localhost:8000/api/binance_pay_qr/`,
          `https://alpha.imeicheck.com/api/deposit/binance_pay_qr`,

          { amount: this.amount },
          {
            headers: {
              // "Content-type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              // Authorization: `Bearer 72738|cU8Ihjr11KHdUu7Z8jbQl6HBYLdsOhT16U3pTcsk`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.qr_image = response.data.data.qrcodeLink;
          this.binance_checkout = response.data.data.checkoutUrl;
          this.qr_generated = true;
        })

        .catch((error) => {
          console.log(error);
          alert("Something went wrong! \n Please try again or contact Admin!");
        });
    },
  },
  components: {
    BInputGroup,
    BFormInput,
    ToastificationContent,
  },
};
</script>

<style>
#stripe-element-errors {
  color: #f44336;
  font-size: 18px;
  margin-top: 10px;
}

p.binance_qr {
  border: 1px dashed;
  padding: 20px;
  background: #161e312b;
  text-align: left;
}
</style>
