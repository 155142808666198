<template>
  <div>
    <p>
      <center>
        USDT (Tether) TRC20 Wallet:<br /><br />
        <b>TDu85GDqCGGj21CjtHH41pM2oYRmqLzq5Z</b> <br /><br />
      </center>
      <b
        >After transfer please send us message with your Email and
        Transaction</b
      ><br />
      <a
        href="https://wa.me/353852108011?text=USDT TRC20 MANUAL PAYMENT"
        target="_blank"
        >WhatsApp Contact Link</a
      >
      Or WhatsApp NR: +353 85 210 801
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p {
  border: 1px dashed;
  padding: 20px;
  background: #161e312b;
  text-align: left;
}
</style>
